






























































import { Component, Mixins } from 'vue-property-decorator'
import TrashCan16 from '@carbon/icons-vue/es/trash-can/16'
import Settings16 from '@carbon/icons-vue/es/settings/16'
import Edit16 from '@carbon/icons-vue/es/edit/16'
import Add16 from '@carbon/icons-vue/es/add/16'
import axios from 'axios'
import camelCaseKeys from 'camelcase-keys'
import isEmpty from 'lodash/isEmpty'
import snakeCaseKeys from 'snakecase-keys'

import TablePagination from '@/partials/tables/Pagination.vue'
import PermissionsMixin from '@/mixins/permissionsMixin'
import FlashMessageMixin from '@/mixins/flashMessageMixin'
import GenericModalDelete from '@/partials/modals/generic/delete.vue'

@Component({
  components: {
    TablePagination,
    Settings16,
    TrashCan16,
    Edit16,
    Add16,
    GenericModalDelete
  }
})
export default class QuestionsDraftsIndex extends Mixins(PermissionsMixin, FlashMessageMixin) {
  filteredData = []
  deleteModalIsOpened = false

  questionsDrafts = []
  totalQuestions = 0

  selectedQuestion = {} as any

  fetchDrafts (page = 1, perPage = 20) {
    const perPageSnake = snakeCaseKeys({ perPage })
    axios.get('question?draft=true', {
      params: {
        'page[number]': page,
        ...perPageSnake
      }
    })
      .then(response => {
        this.questionsDrafts = this.filteredData = camelCaseKeys(response.data.data)
        this.totalQuestions = response.data.total
      })
  }

  search (searchString: string) {
    this.filteredData = this.questionsDrafts.filter((question: QuestionDraft) => {
      let { code } = question

      code = code.toLowerCase()
      searchString = searchString.toLowerCase()

      if (code.includes(searchString)) {
        return question
      }
    })
  }

  postSubmit (message: string) {
    this.deleteModalIsOpened = false

    const flashMessage: flashMessage = {
      message: message,
      type: 'success'
    }

    this.setFlashMessage(flashMessage)

    this.fetchDrafts()
  }

  onPageChange (pagination: Pagination) {
    this.fetchDrafts(pagination.page, pagination.length)
  }

  toggleModal (modalName: string) {
    this.$data[modalName] = !this.$data[modalName]
  }

  get deleteUrl () {
    return isEmpty(this.selectedQuestion) ? '' : `question/${this.selectedQuestion.id}`
  }
}
